import React from "react";
import { Checkbox } from "@chakra-ui/checkbox"
import { Box, Flex, HStack, Text } from "@chakra-ui/layout"
import { useBoolean } from "@chakra-ui/react"
import { useState } from "react"
import { useGetAllBookableAdvisors } from "../api/useAdvisorApi"
import { useGetDays } from '../api/useCalendarApi'
import { Calendar } from "../components/CalendarOverview/Calendar"
import * as CalendarStyles from "../components/CalendarOverview/CalendarStyles"
import { useDebouncedState } from "../helpers/debounce";
import { useGetLocations } from "../api/useLocationApi";
import { DataList, OptionValue } from "../components/DataList";
import ContentWithHeading from "../components/ContentWithHeading";
import { LocationConfiguration } from "../api/client";
import { useTranslation } from "react-i18next";

const labelStyle = {
  minW: 120,
  pb: .5,
  px: 2,
  textAlign: 'center',
  border: '1px solid #00000000'
};

const initWeekMonday = new Date();
const dayOffset = initWeekMonday.getUTCDay() === 0 ? 6 : initWeekMonday.getUTCDay() - 1;
initWeekMonday.setDate(initWeekMonday.getUTCDate() - dayOffset); //+ 1 for monday
initWeekMonday.setHours(0, 0, 0, 0);
export const CalendarOverviewPage = () => {
  const { t } = useTranslation();
  const [weekMonday, setWeekMonday] = useState(initWeekMonday);
  const { data: advisors } = useGetAllBookableAdvisors();
  const nextWeekMonday = React.useMemo(() => {
    const d = new Date(weekMonday);
    d.setDate(d.getDate() + 7);
    return d;
  }, [weekMonday]);
  const [selectedAdvisors, setSelectedAdvisors] = useDebouncedState<string[] | null>(null, 750);
  const { data: days } = useGetDays({ startDate: weekMonday.toDateString(), endDate: nextWeekMonday.toDateString(), advisorEmails: selectedAdvisors || undefined });
  const [openTimesOnly, setOpenTimesOnly] = useBoolean(false);
  const { data: locations } = useGetLocations();
  const [selectedLocations, setSelectedLocations] = useState<LocationConfiguration[]>();
  const advisorOptions = React.useMemo(() => {
    if (advisors) {
      const filteredAdvisors = selectedLocations ?
        advisors.filter(x => x.location && selectedLocations
          .some(selLoc => selLoc.location === x.location)) : advisors;
      return filteredAdvisors.map(a => {
        return {
          value: a.email || "",
          label: `${a.initials} - ${a.displayName} ${(a.location ? `- ${a.location}` : ``)}`
        };
      });
    }
  }, [advisors, selectedLocations]);

  const locationOptions = React.useMemo(() => {
    if (locations) {
      return locations.map(loc => {
        return { value: loc.id, label: loc.location };
      })
    }
  }, [locations]);



  function handleAdvisorSelectChange(options: OptionValue[]) {
    if (options && options.length > 0) {
      const filteredAdvisors = advisors?.filter(adv => options.some(opt => opt.value === adv.email));
      if (filteredAdvisors) {
        const advisorEmails = filteredAdvisors.map(fadv => fadv.email).filter(email => !!email) as string[];
        setSelectedAdvisors(advisorEmails);
      }
    } else {
      setSelectedAdvisors(null);
    }
  }

  function handleLocationChange(options: OptionValue[]) {
    if (options && options.length > 0) {
      setSelectedLocations(locations?.filter(loc => options.some(opt => opt.value === loc.id)));
    } else {
      setSelectedLocations(undefined);
    }
  }

  return (
    <Flex flexDir='column' flex='1 0 0' w='100%' h="100%">
      <HStack w={["100%", null, null, null, null, "70%", "50%"]} alignItems="stretch" spacing={8}>
        <Box w="50%">
          <ContentWithHeading heading={t('calendarpage.location-search.header')}>
            <DataList placeholder={t('calendarpage.location-search.placeholder')} values={locationOptions} isMulti={true} handleOnChange={handleLocationChange} />
          </ContentWithHeading>
        </Box>
        <Box w="50%">
          <ContentWithHeading heading={t('calendarpage.employee-search.header')}>
            <DataList placeholder={t('calendarpage.employee-search.placeholder')} values={advisorOptions} isMulti={true} handleOnChange={handleAdvisorSelectChange} closeMenuOnSelect={false} />
          </ContentWithHeading>
        </Box>
      </HStack>
      <Flex gridGap={'5px'} pb='5px' align='end' whiteSpace='nowrap' wrap='wrap-reverse'>
        <Flex gridGap='5px 5px' wrap='wrap' userSelect='none'>
          <Text {...CalendarStyles.available} sx={labelStyle} borderRadius="md">{t('calendarpage.calendar-label.availability')}</Text>
          <Text {...CalendarStyles.booked} sx={labelStyle} borderRadius="md">{t('calendarpage.calendar-label.customermeeting')}</Text>
          <Text {...CalendarStyles.busy} sx={labelStyle} borderRadius="md">{t('calendarpage.calendar-label.owncalendar')}</Text>
          <Text border={'1px solid'} borderColor={'gray.200'} sx={labelStyle} borderRadius="md">{t('calendarpage.calendar-label.unavailable')}</Text>
        </Flex>
        <HStack gridGap={5} flex='1 auto' justify='right' py={2} wrap='wrap'>
          <Checkbox size='lg' borderColor='black' isChecked={openTimesOnly} onChange={setOpenTimesOnly.toggle}>{t('calendarpage.checkbox.only-available')}</Checkbox>
        </HStack>
      </Flex>
      <Calendar currentWeekMonday={weekMonday} onWeekChange={setWeekMonday} days={days} openTimesOnly={openTimesOnly} />
    </Flex>
  )
}