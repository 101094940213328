import React from "react";
import { IconButton } from '@chakra-ui/button';
import { useBoolean } from '@chakra-ui/hooks';
import { Box, Flex, Grid, HStack, Text } from '@chakra-ui/layout';
import { useEffect, useRef } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { MeetingDay, TimeSlot, TimeSlotStatus, Workday } from '../../api/client';
import { available, booked, busy, ScrollButtonStyle } from './CalendarStyles';
import WeekSelectHeader from './WeekSelectHeader';
import { Time } from "../../models/Time";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

interface CalendarProps {
    onWeekChange: (weekMonday: Date) => void;
    currentWeekMonday: Date;
    days?: MeetingDay[];
    openTimesOnly?: boolean;
}

const lastColumn = {
    borderRight: 'none'
};

const SameDay = (dateA: Date, dateB: Date) => {
    return dateA.getFullYear() === dateB.getFullYear() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getDate() === dateB.getDate();
}

const GetTimeslotStyle = (status: TimeSlotStatus | "available") => {
    switch (status) {
        case TimeSlotStatus.Booked:
            return booked;
        case TimeSlotStatus.Reserved:
            return booked;
        case TimeSlotStatus.Busy:
            return busy;
        default:
            return available;
    }
}

interface DayProps extends MeetingDay {
    openTimesOnly?: boolean;
}

interface AdvisorDayProps {
    date: Date;
    timeSlots: TimeSlot[];
    workday?: Workday;
    openTimesOnly?: boolean;
}

const Header = ({ date, advisorDays }: DayProps) => {
    let dateString = date?.toLocaleString(i18n.language, { weekday: 'long' }) + ' ' + ('0' + date?.getDate()).slice(-2) + '/' + date?.toLocaleDateString('da-DK', { month: '2-digit' });
    dateString = dateString.slice(0, 1).toUpperCase() + dateString.slice(1);
    return (
        <Box borderRight={'1px solid'} borderColor={'gray.800'} _last={lastColumn} w="100%" overflow="hidden">
            <Text textAlign='center' minH='40px' minW='150px' py='2' fontSize="lg" fontWeight="bold">{dateString}</Text>
            <HStack spacing={0} h='40px' w="100%">
                {advisorDays?.map((d) =>
                    <Box key={d.advisorId} w="100%" minW="16" h='100%' borderRight='1px solid' borderColor='gray.200' _last={lastColumn}>
                        <Text key={d.advisorId} px='1' pt='2' align='center' isTruncated>{d.title}</Text>
                    </Box>
                )}
            </HStack>
        </Box>
    )
};

export const AdvisorDayColumn = ({ date, timeSlots, openTimesOnly, workday }: AdvisorDayProps) => {
    const fullDayTimeslots = React.useMemo(() => {
        const timeSlotsForThisDate = timeSlots;
        const timeSlotsForThisDateIncludingEmpty: (TimeSlotStatus | null | "available")[] = [];
        let workdayFrom: Time | null = null;
        let workdayTo: Time | null = null;
        if (workday !== null && workday !== undefined) {
            workdayFrom = Time.fromString(workday.from);
            workdayTo = Time.fromString(workday.to);
        }
        for (let i = 0; i < 48; i++) {
            const dateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), i / 2, i % 2 === 1 ? 30 : 0, 0);
            const timeSlotForThisSlot = timeSlotsForThisDate.find(x => x.startDate !== undefined
                && x.endDate !== undefined && x.startDate <= dateTime && dateTime < x.endDate);
            if (timeSlotForThisSlot !== undefined) {
                if (timeSlotForThisSlot.status === TimeSlotStatus.ExtraOpen) {
                    timeSlotsForThisDateIncludingEmpty.push("available");
                } else if (!openTimesOnly) {
                    timeSlotsForThisDateIncludingEmpty.push(timeSlotForThisSlot.status || null);
                } else {
                    timeSlotsForThisDateIncludingEmpty.push(null);
                }
            } else if (workdayFrom !== null && workdayTo !== null &&
                workdayFrom.calculateTimeDiff(dateTime) >= 0 && workdayTo.calculateTimeDiff(dateTime) < 0 && workday?.isAvailable === true) {
                timeSlotsForThisDateIncludingEmpty.push("available");
            } else {
                timeSlotsForThisDateIncludingEmpty.push(null);
            }

        }
        return timeSlotsForThisDateIncludingEmpty;
    }, [timeSlots, date, openTimesOnly, workday]);

    return (
        <Grid templateRows="repeat(48, 1fr)" w="100%" minW="16" templateColumns="1fr" h='100%' borderRight={'1px solid'} _last={lastColumn} borderColor='gray.200' pos='relative' bg='backgroundGrey'>
            {fullDayTimeslots?.map((t, i) => {
                const style = t === null ? {} : GetTimeslotStyle(t);
                return <Box key={`timeslot-${date?.toISOString()}-${i}`} w="100%" h="100%" borderBottom="1px solid" borderColor={i % 2 === 0 ? "gray.100" : "gray.200"} {...style} gridRowStart={i + 1} gridColumnStart="1" />
            })}
        </Grid>
    )
};

export const DayColumn = ({ advisorDays, openTimesOnly }: DayProps) => {
    const advisorDayColumns = advisorDays?.map((d) => <AdvisorDayColumn key={d.advisorId} {...d} openTimesOnly={openTimesOnly} />)
    return (
        <HStack w="100%" h='100%' spacing={0} alignItems='start' borderRight={'1px solid'} _last={lastColumn} borderColor="gray.800">
            {advisorDayColumns}
        </HStack>
    );
};

export const Calendar = ({ onWeekChange, currentWeekMonday, days: daysProp, openTimesOnly }: CalendarProps) => {
    const { t } = useTranslation();
    const [dayHeaders, dayColumns] = React.useMemo(() => {
        let days = Array<MeetingDay>(7);
        if (daysProp !== undefined) {
            days = daysProp;
        } else {
            const date = new Date(currentWeekMonday);
            for (let i = 0; i < days.length; i++) {
                days[i] = { date: new Date(date) };
                date.setDate(date.getDate() + 1);
            }
        }
        const dayHeaders: JSX.Element[] = Array(days?.length);
        const dayColumns: JSX.Element[] = Array(days?.length);
        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            dayHeaders.push(<Header key={day.date?.getTime()} date={day.date} advisorDays={days[i].advisorDays} openTimesOnly={openTimesOnly} />);
            dayColumns.push(<DayColumn key={day.date?.getTime()} date={day.date} advisorDays={days[i].advisorDays} openTimesOnly={openTimesOnly} />);
        }
        return [dayHeaders, dayColumns];
    }, [daysProp, currentWeekMonday, openTimesOnly]);

    const times = React.useMemo(() => {
        const times = Array<string>(24);
        for (let i = 0; i < times.length; i++) {
            times[i] = ('0' + i).slice(-2) + ':00';
        }
        return times;
    }, []);

    const [atTop, setAtTop] = useBoolean(false);
    const [showLeft, setShowLeft] = useBoolean(false);
    const [showRight, setShowRight] = useBoolean(false);
    const [currentTimeTop, setCurrentTimeTop] = React.useState(0);

    const gridRef = useRef<HTMLDivElement>(null);
    const columnStackRef = useRef<HTMLDivElement>(null);
    const timeColumnRef = useRef<HTMLDivElement>(null);
    const timeBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        updateScrollStates();
        window.addEventListener('resize', updateScrollStates);
        return () => window.removeEventListener('resize', updateScrollStates);
    });

    function calculateCurrentTimeTop() {
        if (timeBoxRef.current === null) return;
        const height = timeBoxRef.current.getBoundingClientRect().height;
        const currentTime = new Date();
        const currentDayMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();
        const dayProgressedPercentage = currentDayMinutes / (24 * 60);
        const top = height * dayProgressedPercentage;
        setCurrentTimeTop(top);
    }

    useEffect(() => {
        if (timeBoxRef.current !== null) {
            calculateCurrentTimeTop();
        }
        const interval = window.setInterval(calculateCurrentTimeTop, 60000);
        return () => window.clearInterval(interval);
    }, []);

    const updateScrollStates = () => {
        const gridEl = gridRef.current;
        if (!gridEl) return;

        const scrollTop = gridEl.scrollTop === 0;
        if (!atTop && scrollTop) setAtTop.on();
        else if (atTop && !scrollTop) setAtTop.off();

        const scrollStart = gridEl.scrollLeft === 0;
        if (!showLeft && !scrollStart) setShowLeft.on();
        else if (showLeft && scrollStart) setShowLeft.off();

        const scrollEnd = (gridEl.offsetWidth + gridEl.scrollLeft) >= gridEl.scrollWidth;
        if (!showRight && !scrollEnd) setShowRight.on();
        else if (showRight && scrollEnd) setShowRight.off();
    };

    const onScrollClick = (left: boolean = false) => {
        const gridEl = gridRef.current;
        const columnStackEl = columnStackRef.current;
        const timeColEl = timeColumnRef.current;
        if (!gridEl || !columnStackEl || !timeColEl) return

        const timeColWidth = timeColEl.offsetWidth;
        const clientWidth = gridEl.clientWidth;
        const scrollLeft = gridEl.scrollLeft;

        const children = columnStackEl.children;

        // Scroll to start or end of first partially shown column
        let scroll: number = 0;
        for (let i = 0; i < children.length; i++) {
            const col = children[i] as HTMLElement;
            const colEnd = col.offsetLeft + col.offsetWidth + timeColWidth;
            const scrolledColEnd = colEnd - scrollLeft;

            if (left && scrolledColEnd >= timeColWidth) {
                scroll = col.offsetLeft - 1; // -1 to include last border
                break
            } else if (scrolledColEnd > clientWidth) {
                scroll = colEnd - clientWidth;
                break
            }
        }

        gridEl.scrollTo({ left: scroll, behavior: 'smooth' })
    };

    return (
        <Flex flex='1 0 0' flexDir='column' minH={['95vh', '95vh', '500px']} boxShadow="lg" borderRadius="lg" overflow="hidden">
            <WeekSelectHeader onWeekChange={onWeekChange} currentWeekMonday={currentWeekMonday} />
            <Box pos='relative' minH='0' flex='1 1 0'>
                <Grid ref={gridRef} gridTemplate='auto 1fr / auto 1fr' h='100%' bg='white' overflow='auto' userSelect='none' onScroll={updateScrollStates}>
                    {/* Reverse order of elements to not use z-index */}
                    <Box ref={timeBoxRef} position="relative" gridArea="2 / 2 / 3 / 3">
                        <HStack width="100%" height="100%" spacing={0}>
                            {dayColumns}
                        </HStack>
                        <Box position="absolute" left="0" right="0" top={currentTimeTop - 1 + "px"} borderTop="2px solid" borderColor="blue.500" />
                    </Box>
                    <HStack ref={columnStackRef} bgColor="inherit" pos='sticky' top='0' gridArea='1 / 2 / 1 / 3' spacing={0} boxShadow={atTop ? 'none' : 'md'}>
                        {dayHeaders}
                    </HStack>
                    <Grid ref={timeColumnRef} textAlign="right" pr="4" mt="-4" gridTemplateRows="repeat(48, 1fr)" gridTemplateColumns="100%" h="100%" w={['3em', '3em', '5em']} gridArea='2/1 / 2/2' alignItems="center"> {/*40px * 24 = 960px*/}
                        {times.map((t, i, e) => <Box key={t} gridRowStart={1 + i * 2} gridColumnStart="1" h="100%">
                            <Text>{t}</Text>
                        </Box>)}
                    </Grid>
                    <Box gridArea='1/1 / 2/2'>
                    </Box>
                </Grid>
                <IconButton hidden={!showLeft} aria-label={t('calendarpage.calendar.scoll-left-label')} left={['3.2em', '3.2em', '5.2em']} {...ScrollButtonStyle} icon={<FiChevronLeft fontSize='1.5em' strokeWidth='4px' />} onClick={() => onScrollClick(true)} />
                <IconButton hidden={!showRight} aria-label={t('calendarpage.calendar.scoll-right-label')} right='1.2em' {...ScrollButtonStyle} pl='2px' icon={<FiChevronRight fontSize='1.5em' strokeWidth='4px' />} onClick={() => onScrollClick()} />
            </Box>
        </Flex>
    );
};
