/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TimeSlotStatus {
    Reserved = 'Reserved',
    Booked = 'Booked',
    Busy = 'Busy',
    ExtraOpen = 'ExtraOpen',
    WorkingFromElsewhere = 'WorkingFromElsewhere'
}

export function TimeSlotStatusFromJSON(json: any): TimeSlotStatus {
    return TimeSlotStatusFromJSONTyped(json, false);
}

export function TimeSlotStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSlotStatus {
    return json as TimeSlotStatus;
}

export function TimeSlotStatusToJSON(value?: TimeSlotStatus | null): any {
    return value as any;
}

