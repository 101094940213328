import { ColumnLayout } from "../components/layout/ColumnLayout";
import { useState, useMemo, useRef } from "react";
import { CompetenceGroup } from "../api/client";
import { CreateCompetenceGroup } from "../components/CompetenceGroup/CreateCompetenceGroup";
import { useGetAllCompetenceGroups } from "../api/useCompetenceGroupApi";
import { GroupItemsList } from "../components/ServiceGroup/GroupItemList";
import { CompetenceGroupOverview } from "../components/CompetenceGroup/CompetenceGroupOverview";
import { useTranslation } from "react-i18next";

export const CompetenceGroupPage = () => {
    const [selectedCompetenceGroup, setSelectedCompetenceGroup] = useState<CompetenceGroup | undefined>();
    const { data: competenceGroups } = useGetAllCompetenceGroups();
    const { t } = useTranslation();

    const groupItems = useMemo(() => {
        if (!competenceGroups) return [];

        return competenceGroups?.map((competenceGroup) => {
            return {
                name: competenceGroup.name!,
                id: competenceGroup.id!,
            }
        });
    }, [competenceGroups]);

    const selectedGroupItem = useMemo(() => {
        if (!selectedCompetenceGroup) return undefined;
        return {
            name: selectedCompetenceGroup?.name!,
            id: selectedCompetenceGroup?.id!
        };
    }, [selectedCompetenceGroup]);

    function handleGroupItemClicked(id: string | undefined): void {
        const competenceGroup = competenceGroups?.find((competenceGroup) => competenceGroup.id === id);
        setSelectedCompetenceGroup(competenceGroup);
    }

    return (
        <ColumnLayout>
            <GroupItemsList w="30%" minHeight="500px" heading={t('competencegrouppage.group-list.heading')} groupItems={groupItems} selectedGroupItemInput={selectedGroupItem} handleGroupItemClicked={handleGroupItemClicked}></GroupItemsList>
            {!selectedCompetenceGroup || !selectedCompetenceGroup.id ?
                <CreateCompetenceGroup onGroupCreated={setSelectedCompetenceGroup} />
                :
                <CompetenceGroupOverview
                    handleParentClicked={id => handleGroupItemClicked(id)}
                    competenceGroupId={selectedCompetenceGroup.id}
                    handleBack={() => handleGroupItemClicked(undefined)}
                    handleDelete={() => handleGroupItemClicked(undefined)}>
                </CompetenceGroupOverview>
            }
        </ColumnLayout>
    );

}
